h1 {
  text-align: center;
}

/* li {
  cursor: pointer;
} */

.homeBody {
  padding: 0.25rem 0.5rem;
  width:100%;
  max-width: 50rem;
  min-height: 70vh;
  margin: auto;
  font-size: 1.1rem;
  background-color: rgb(250, 250, 250);
  border-radius: 2px;
  /* box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.homeExpand {
  cursor: pointer;
}